<template>
    <div style="background-color: #144967;" class="vh-100 position-relative">
        <div class="container-lg h-100 position-relative" style="z-index: 1;">
            <div class="row align-items-center h-100" style="gap:10px 0">
                <div class="col-lg-7">
                    <div class="h2 text-white text-justify">
                        Mongrela was created with one purpose in mind: to help stray animals find loving homes and
                        ensure they receive the care they deserve. Every year, millions of stray animals are left
                        without food, shelter, or medical attention.
                        <br><br>
                        It is estimated that there are 70–80 million stray dogs in Southeast Asia, with the majority
                        living in urban areas and rural communities.
                        <br><br>
                        Rabies is a major public health concern in Southeast Asia, with stray animals being one of the
                        main carriers. Thailand, Indonesia (especially Bali), and the Philippines have all faced rabies
                        outbreaks linked to stray dogs.
                        <br><br>
                        By collaborating with shelter around South East Asia and collecting our minds together to help
                        our fur friends in need and to also mitigate the public concern about strays we are here to be
                        the platform for us to reach our hands collectively with care
                    </div>
                </div>
                <div class="col-lg-5">
                    <div class="d-flex flex-column align-items-center">
                        <img src="@/static/logo - Copy.png" class="" style="max-width:300px" />
                        <div style="color: #FFAD32;" class="display-3 fw-bolder mb-0">
                            Join Our Cause
                        </div>
                        <router-link to="/register">
                            <div class="px-3 py-1 h4 fw-bolder text-white rounded-pill"
                                style="background-color: #005071;border: 1px solid #FFAD32;">
                                Sign Up
                            </div>
                        </router-link>
                        <div style="width: 200px;height: 1px;background: white;" class="my-2"></div>

                        <img src="@/static/qr-code.png" style="max-width: 200px;border-radius: 5px;" />
                        <div class="text-white h4 fw-bolder mb-0" style="margin-top: .5em;">Scan Here</div>
                    </div>
                </div>
            </div>
        </div>
        <img src="@/static/dog-left-right.png" class="position-absolute w-100"
            style="bottom:0;max-width:800px;opacity: .4;" />
    </div>
</template>

<script>

export default {

}

</script>