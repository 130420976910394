<template>
    <section class="slider-container" style="border: 2px solid white;background:#144967" id="news">

        <div class="slide" v-for="(slide, index) in slides" :key="index" :class="{ active: index === currentSlide }">
            <img :src="slide.banner_picture" alt="" class="slide-image" />
            <div class="slide-content">
                <h2 class="text-white h1">| News</h2>
                <h3 class="text-white display-5">{{ slide.banner_title }}</h3>
                <p class="text-white h3 mb-0">{{ slide.banner_date }}</p>
            </div>
        </div>

        <!-- Indicators -->
        <div class="indicator-container">
            <div v-for="(slide, index) in slides" :key="index" class="indicator"
                :class="{ active: index === currentSlide }" @click="goToSlide(index)"></div>
        </div>
    </section>
</template>

<script>
import store from '@/store'

export default {
    data() {
        return { 
            // slides: [
            //     {
            //         image: 'https://www.americanhumane.org/app/uploads/2018/04/shelter-cats.jpg',
            //         category: 'News',
            //         title: 'Happy ending for two stray dogs rescued from Bisley Reserve',
            //         date: '17 October 2024',
            //     },
            //     {
            //         image: 'https://www.americanhumane.org/app/uploads/2018/04/shelter-cats.jpg',
            //         category: 'News',
            //         title: 'Second Slide Title Here',
            //         date: '20 October 2024',
            //     },
            //     {
            //         image: 'https://www.americanhumane.org/app/uploads/2018/04/shelter-cats.jpg',
            //         category: 'News',
            //         title: 'Third Slide Title Here',
            //         date: '22 October 2024',
            //     },
            // ],
            currentSlide: 0,
            autoSlideInterval: null, // Store the interval reference here
        };
    },
    computed: {
        slides() {
            return store.state.banners.BANNERS || []
        },
    },
    mounted() {
        this.startAutoSlide(); // Start auto-slide when component is mounted
    },
    beforeDestroy() {
        this.stopAutoSlide(); // Stop auto-slide when component is destroyed
    },
    methods: {
        startAutoSlide() {
            this.autoSlideInterval = setInterval(() => {
                this.nextSlide();
            }, 4000); // Change slide every 5 seconds (5000 ms)
        },
        stopAutoSlide() {
            clearInterval(this.autoSlideInterval); // Clear the interval
        },
        resetAutoSlide() {
            this.stopAutoSlide();
            this.startAutoSlide(); // Restart the auto-slide timer
        },
        nextSlide() {
            this.currentSlide = (this.currentSlide + 1) % this.slides.length;
            this.resetAutoSlide(); // Reset timer on manual change
        },
        prevSlide() {
            this.currentSlide = (this.currentSlide - 1 + this.slides.length) % this.slides.length;
            this.resetAutoSlide();
        },
        goToSlide(index) {
            this.currentSlide = index;
            this.resetAutoSlide();
        },
    },
};
</script>

<style scoped>
.slider-container {
    position: relative;
    width: 100%;
    aspect-ratio: 16/9;
    overflow: hidden;
    border-radius: 15px;
}

.slide {
    position: absolute;
    top: 100%; /* Start below the view */
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: top 0.8s ease-in-out, opacity 0.8s ease-in-out;
}

.slide.active {
    top: 0; /* Move into view */
    opacity: 1;
    z-index: 1;
}

.slide-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.slide-content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 40%;
    /* width: 50%;
    height: 100%; */
    background-color: rgba(20, 73, 103, .5);
    /* background: rgba(0,0,0,.5); */
    color: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 0 60px 0 0;
}

.arrows {
    display: flex;
    justify-content: space-between;
}

.arrow {
    font-size: 1.5em;
    color: #FFAD32;
    cursor: pointer;
}

.indicator-container {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    display: flex;
    z-index: 99;
    flex-direction: column;
    gap: 10px;
}

.indicator {
    width: 10px;
    height: 10px;
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;
    opacity: 0.5;
    transition: background-color 0.3s, transform 0.3s;
}

.indicator.active {
    background-color: #FFAD32;
    opacity: 1;
    transform: scale(1.2);
}
</style>
