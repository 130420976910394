<template>
    <div class="banner-container px-5 pt-1 pb-3 position-relative mt-2">
        <!-- Icon Section -->
        <div class="d-flex align-items-end mt-5 mb-5 position-relative" style="z-index:1; gap:10px 60px">
            <div class="icon-item">
                <img src="@/static/img_assets/Dog Paw Print.png" />
                <p class="h3 text-white mb-0">Adopt</p>
            </div>
            <div class="icon-item">
                <img src="@/static/img_assets/Handshake.png"  />
                <p class="h3 text-white mb-0">Volunteer</p>
            </div>
            <div class="icon-item">
                <img src="@/static/img_assets/Money.png" />
                <p class="h3 text-white mb-0">Donate</p>
            </div>
        </div>

        
        <!-- Main Message -->
        <div class="text-left display-1 text-white position-relative" style="z-index: 1;">
            TO GIVE &nbsp;&nbsp; TO HELP &nbsp;&nbsp; TO LOVE
        </div>
        
        <div class="text-white h3 mb-0 position-relative mt-4" style="z-index: 1;">Pet Goes Social</div>
        
        <div class="image-overlay position-absolute" style="top: 0;right: 0;">
            <img src="@/static/img_assets/Artboard 1.jpg" class="h-100 " />
        </div>
 
    </div>
</template>

<script>
export default {
    data() {
        return { 
        };
    },
};
</script>

<style scoped>
.banner-container {
    /* background: url('') no-repeat center; */
    background-color: #4C98BF;
    background-size: cover;
    color: white;
    text-align: center; 
    position: relative; 
}  

.image-overlay {
  position: relative;
  height: 100%; /* Set this to the height you need */
  width: 1200px; /* Or set a specific width */
  overflow: hidden;
}

.image-overlay img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-overlay::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to right, #4C98BF, rgba(0, 0, 0, 0)); 
  pointer-events: none;  
}
</style>