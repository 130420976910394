<template>
    <div class="position-relative" style="overflow: hidden;background: transparent;"> 
        <img src="@/static/img_assets/doggie-09 6@2x.png" class="position-absolute h-100" style="opacity: 1;right: 0;" />
        <img src="@/static/img_assets/Artboard 9.png" class="position-absolute" style="right: 0; bottom: 0;height: 200px;" />
        <div class="container-lg py-3 position-relative" style="z-index: 1;">
            <div class="d-flex flex-column my-3 align-items-center justify-content-center" style="gap:10px">
                <img src="@/static/logo - Copy.png" style="max-width:300px" />
                <div class="text-white h3">Pet Goes Social</div>
                <div class="fw-bolder text-white display-3 mb-0">Be a Part Of Our Packs</div>
                <router-link to="/register" class="px-3 py-1 h4 fw-bolder text-white rounded-pill mb-0"
                    style="background-color: #005071;border: 1px solid #FFAD32;">
                    Join Us Now
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
        };
    },
};
</script>